import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setSubmitting(true);
    setValidated(true);

    try {
      const endpoint = process.env.NODE_ENV === "development"
        ? "http://localhost:5002/send"
        : "/.netlify/functions/send-email";
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitStatus("success");
        setFormData({
          name: "",
          email: "",
          message: ""
        });
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error(error);
      setSubmitStatus("error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      id="contactForm"
      name="sentMessage"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col md="6">
          <Form.Group>
            <Form.Control
              id="name"
              type="text"
              placeholder="Your Name *"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              id="email"
              type="email"
              placeholder="Your Email *"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Control
              as="textarea"
              id="message"
              placeholder="Your Message *"
              required
              value={formData.message}
              onChange={handleChange}
              style={{ height: "150px" }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your message.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <div className="clearfix"></div>
        <Col lg="12" className="text-center">
          {submitStatus === "success" && (
            <div className="text-success mb-3">Message sent successfully!</div>
          )}
          {submitStatus === "error" && (
            <div className="text-danger mb-3">
              Failed to send message. Please try again.
            </div>
          )}
          <Button
            id="sendMessageButton"
            className="btn-xl text-uppercase"
            type="submit"
            disabled={submitting}
          >
            {submitting ? "Sending..." : "Send Message"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
